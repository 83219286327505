.sidebar{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 220px;
    background-color: royalblue;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;    
    z-index: 100;
}

.sidebar ul{
    list-style-type: none;
    text-align: center;
    width: 70%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}