.feedback-header {
    position: relative;
    width: calc(100vw - 220px);
    min-width: 1400px;
    height: 150px;
    left: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feedback-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    margin: 0 50px;
    margin-bottom: 20px;
}

.feedback-container h2 {
    position: absolute;
    left: 0;
    margin: 0 100px;
    font-size: 1.8em;
    padding: 0;
}

.feedback-column-header {
    position: relative;
    font-weight: bold;
    background-color: #f0f0f0;
    display: flex;
    flex-direction: row;
    width: 1400px;
    min-width: 1400px;
    border-radius: 10px;
    padding: 10px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.feedback-column-header .timestamp-column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    margin-left: 40px;
}

.feedback-column-header .videoId-column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
}

.feedback-column-header .date-column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
}

.feedback-column-header .title-column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
}

.feedback-column-header .timecode-column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
}

.feedback-column-header .message-column{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 490px;
}

.feedbacks {
    position: relative;
    left: 220px;
    height: calc(100vh - 200px);
    width: calc(100vw - 220px);
    min-width: 1400px;
    background-color: whitesmoke;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.feedback {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1400px;
    border-radius: 10px;
    background-color: white;
    height: max-content;
    min-height: 48px;
    margin: 10px;
    padding: 15px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.feedback span {
    font-size: 1em;
    margin: 0 10px;
}


.feedback .timestamp-column {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}

.feedback .videoId-column {
    display: flex;
    width: 150px;
}

.feedback .date-column {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
}

.feedback .title-column {
    justify-content: center;
    align-items: center;
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.feedback .timecode-column {
    width: 80px;
    display: flex;
    justify-content: center;
}

.feedback .message-column {
    display: flex;
    flex-wrap: wrap;
    width: 480px;
    margin-left: 10px;
}