.profile{
    z-index: 10;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 100px;
}
.profile-name{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.profile-name span{
    font-size: 1em;
    font-weight: 400;
    margin-left: 10px;
    padding: 10px;
}

.menu{
    background-color: whitesmoke;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    position: absolute;
    right: 50;
    top: 4em;
    width: 120px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    padding: 10px;
}

.menu ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.menu ul li{
    padding: 10px;
    cursor: pointer;
}

.menu ul li:hover{
    background-color: lightgray;
    border-radius: 5px;
    padding: 10px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}