.search-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: royalblue; 
    top: 0;
    width: 400px;
    height: 25px;
    border-radius: 20px;
    padding: 10px;
    padding-right: 0;
    margin-right: 50px;
}

.search-bar input{
    background-color: transparent;
    border: none;
    color: white;
    width: 80%;
    font-size: 1em;
    float: left;
}

.search-bar input:focus{
    outline: none;
}

.search-bar input::placeholder{
    color: whitesmoke;
    font-weight: bold;
}

.search-bar button{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 1em;
    cursor: pointer;
}

.search-bar button:focus{
    outline: none;
}

.search-icon{
    position: relative;
    width: 10px;
    height: 10px;
    color: white;
}