.board {
    position: relative;
    top: 0;
    left: 0;
    left: 220px;
    height: calc(100vh - 400px); /*350px*/
    width: calc(100vw - 220px);
    background-color: whitesmoke;
    z-index: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
}

.card {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 1400px;
    border-radius: 10px;
    background-color: white;
    margin: 10px;
    padding: 15px 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card span {
    font-size: 1em;
    font-weight: 400;
    margin: 0 10px;
}

.card .date-column {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
}

.card .videoId-column {
    display: flex;
    width: 150px;
    margin: 0 5px;
}

.card .title-column {
    display: flex;
    flex-wrap: wrap;
    width: 700px;
    text-align: left;
    margin-left: 10px;
}

.members {
    display: flex;
    flex-direction: row;
    justify-content: first baseline;
    align-items: center;
    flex-wrap: wrap;
    width: 250px;
    margin: 0 20px;
}

.members span {
    font-size: 12px;
    font-weight: 400;
    margin: 2px 3px;
    padding: 3px 10px;
    color: snow;
    font-weight: bold;
    border-radius: 20px;
}

.subtitle {
    display: flex;
    flex-direction: row;
    justify-content: first baseline;
    flex-wrap: wrap;
    width: 300px;
}


#langCode-chip {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    margin: 2px 5px;
    padding: 5px 14px;
    font-weight: bold;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.2);
    width: 30px;
}

.lang-close-icon {
    position: absolute;
    right: 5px;
    background-color: whitesmoke;
    border-radius: 50%;
    width: 18px !important;
    height: 18px !important;
    color: tomato;
    cursor: pointer;
}

.video-title-link {
    color: inherit; 
    text-decoration: none; /* Remove underline */
  }
  
  .video-title-link:visited {
    color: inherit; 
  }
  